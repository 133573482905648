import {mailApi, workOutPlanApi} from "@/api/api.instance";
import {AxiosPromise} from "axios";
import {WorkOutPlan, WorkOutStatus} from "@/api";

export class MailService {
    sendMail(id: number): AxiosPromise<void> {
        return mailApi.sendMail(id);
    }

}

export default new MailService();
