





































import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import {Mail} from "@/api";

@Component
export default class EmailDialog extends Vue {

  @Prop({default: false}) show!: boolean;
  @Prop() email!: Mail;


  get dialog(): boolean {
    return this.show
  }

  set dialog(value: boolean) {
    this.show = value;
  }

  @Emit('agree')
  agree(): number {
    this.dialog = false;
    return this.email?.id!;
  }

  @Emit('cancel')
  cancel(): boolean {
    return false;
  }

  mounted() {
    //
  }
}
