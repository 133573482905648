
































































import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import {Mail, Registration, User} from "@/api";
import registrationService from "@/service/registration.service"
import moment from "moment";
import {STANDARD_DATE_TIME} from "@/constants/date.constants";
import notificationService, {NotificationType} from "@/service/notification.service";
import mailService from "@/service/mail.service";
import MailDialog from "@/components/dialogs/MailDialog.vue";
import {DataTableHeader} from "vuetify";

const Auth = namespace("Auth");
@Component({
  components: {MailDialog}
})
export default class WorkOutReportTable extends Vue {
  @Auth.State("user")
  private currentUser!: User;

  colors = {NOT_PROCESSED: 'orange', PROCESSED: 'green', NOT_VALID: 'indigo'}

  registrations: Registration[] = [];
  loading = false;

  emailDialog = false;

  pool = 0;

  selectedEmail: Mail = {};

  canSend(mail: Mail | undefined): boolean {
    return mail?.canSend!;
  }

  get headers(): any {
    return [
      {
        text: 'ик',
        align: 'start',
        value: 'id',
        width: 100
      },
      {text: 'Имя', value: 'clientName', width: 200},
      {text: 'Номер договора', value: 'contract', width: 100},
      ...this.calculateHeaderDays()
    ]
  }

  calculateHeaderDays(): DataTableHeader[] {
    const days: DataTableHeader[] = [];

    for (let i = 1; i <= 30; i++) {
      days.push({text: i.toString(10), align: 'center', value: i.toString(10),width: 10})
    }
    return days;
  }

  private loadRegistrations() {
    this.loading = true;
    registrationService.getAllRegistrations()
        .then(resp => this.registrations = resp.data)
        .catch(error => {
          notificationService.showNotification(NotificationType.error, error)
        }).finally(() => {
      this.loading = false;
    })
  }

  formatDate(date: string): string {
    return moment(date).format(STANDARD_DATE_TIME)
  }

  mounted() {
    this.loadRegistrations();

    this.pool = setInterval(() => {
      this.loadRegistrations()
    }, 30000)
  }

  openEmail(email: Mail) {
    this.selectedEmail = email;
    this.emailDialog = true;
  }

  sendMail(id: number) {
    this.emailDialog = false;
    mailService.sendMail(id).then(() => {
          this.loadRegistrations();
        }
    ).catch(error => {
      notificationService.showNotification(NotificationType.error, error)
    });
  }

  beforeDestroy() {
    clearInterval(this.pool);
  }

  cancel() {
    this.selectedEmail = {};
    this.emailDialog = false;
  }
}
