import {registrationApi} from "@/api/api.instance";
import {AxiosPromise} from "axios";
import {Registration} from "@/api";

class RegistrationService {
    getAllRegistrations(): AxiosPromise<Array<Registration>> {
        return registrationApi.getAllRegistrations();
    }

    updateRegistration(id: number, body: Registration): AxiosPromise<Registration> {
        return registrationApi.updateRegistration(id, body);
    }
}

export default new RegistrationService();
